import React from 'react';

import Card from '@bit/semantic-org.semantic-ui-react.card'
import Image from '@bit/semantic-org.semantic-ui-react.image'
import Icon from '@bit/semantic-org.semantic-ui-react.icon'
import Statistic from '@bit/semantic-org.semantic-ui-react.statistic'
import Grid from '@bit/semantic-org.semantic-ui-react.grid'

import {graphql, useStaticQuery} from "gatsby";

const siteProjectsQuery = graphql`
  {
    allGraphqlListJson(filter: {category: {in: ["Projects", "Databases", "Services"]}}) {
        edges {
          node {
            id
            name
            category
            category
            twitter
            href {
              name
              base
            }
            path
            stars
            organization
            project
            repo_url
            homepage_url
            description
            crunchbase
            contributorsCount
            organization
          }
        }
      }
  }
`;

const CardsList = (props) => {
    console.log("site" + props.cardsType + "Query")
    const results = useStaticQuery(siteProjectsQuery);
    var allGraphqlListJson = results.allGraphqlListJson.edges;
    // console.log(JSON.stringify(allGraphqlListJson, null, 2))

    return (
        <>
            { allGraphqlListJson.map(api => {
                const path = require('../../../content/landscape/logos/' + api.node.href.base);
                return (
                <>
                    <Card color='green' key={api.node.id}>
                        <Card.Content>
                            <q href={api.node.homepage_url} target="new">
                                <Image floated='right' size='tiny' alt="{api.node.name}" src={path}/>
                            </q>
                            <Card.Header>
                                <a href={api.node.homepage_url} target="new">{api.node.name} ({api.node.category.toLowerCase()})</a>
                                <br/>
                                By {api.node.organization}
                            </Card.Header>
                            <Card.Meta><b>{api.node.description}</b></Card.Meta>
                            <Card.Description>

                                <Statistic.Group widths='two' size="tiny">
                                    <Statistic>
                                        <Statistic.Value>
                                            <Icon name='star'/>{api.node.stars}
                                        </Statistic.Value>
                                        <Statistic.Label>GitHub Stars</Statistic.Label>
                                    </Statistic>

                                    <Statistic>
                                        <Statistic.Value>
                                            <Icon name='user'/>{api.node.contributorsCount}
                                        </Statistic.Value>
                                        <Statistic.Label>Contributors</Statistic.Label>
                                    </Statistic>
                                </Statistic.Group>
                            </Card.Description>
                        </Card.Content>

                        <Card.Content extra>
                            <Grid>
                                <Grid.Row columns={4}>
                                    <Grid.Column>
                                        <a href={api.node.twitter}>Twitter</a>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <a href={api.node.repo_url}>Github</a>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <a href={api.node.crunchbase}>CrunchBase</a>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Card.Content>
                    </Card>
                </>
            )})}
        </>
    )
}


const GraphqlProjectsTools: React.FC = () => {

    return (
        <div>
            <Card.Group stackable itemsPerRow={4}>
                <CardsList cardsType="Projects"/>
            </Card.Group>
        </div>
    )
};

export default GraphqlProjectsTools;

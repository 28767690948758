import React from "react";

import Layout from "@narative/gatsby-theme-novela/src/components/Layout";
import Section from "@narative/gatsby-theme-novela/src/components/Section";
import SEO from "@narative/gatsby-theme-novela/src/components/SEO";
import Headings from "@narative/gatsby-theme-novela/src/components/Headings";
import GraphqlProjectsTools from "../components/GraphqlTools/GraphqlProjectsTools";

function GraphqlToolsPage() {

  let description = "GraphQL Projects, Databases and Services";

  return (
    <Layout>
      <SEO description={description} title="GraphQL Tools - GraphQL Projects, Databases and Services">
      </SEO>
      <Section>
          <div style={{ marginTop: "50px" }}>
              <Headings.h1>GraphQL Projects, Databases and Services</Headings.h1>
              <br/>
              <GraphqlProjectsTools/>
          </div>
      </Section>
    </Layout>
  );
}

export default GraphqlToolsPage;
